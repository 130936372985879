.create-claim-form-layout {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.clfl-section-content {
    column-count: 3;
    /* column-rule: 1px solid lightgray; */
}

.clfl-section-content div {
    -webkit-column-break-inside: avoid;
}

.clfl-field-item {
    /* border: 1px solid lightblue; */
    width: 100%;
    height: fit-content;
    min-height: 56px;
    padding-top: 20px;
}

.ccfl-info-area {
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin-bottom: 20px;
}

.ccfl-info-area-overflow {
    overflow: auto
}

.ccfl-action-area {
    display: flex;
    justify-content: end;
}

@media only screen and (max-width: 1500px) {
    .clfl-section-content {
        column-count: 2;
    }
}

@media only screen and (max-width: 1000px) {
    .clfl-section-content {
        column-count: 1;
    }
}

@media only screen and (max-height: 900px) { 
    .ccfl-info-area {
        height: 400px;
    }
}

@media only screen and (max-height: 1000px) { 
    .ccfl-info-area {
        height: 600px;
    }
}

@media only screen and (max-height: 900px) { 
    .ccfl-info-area {
        height: 450px;
    }
}

@media only screen and (max-height: 700px) {
    .ccfl-info-area {
        height: 300px;
    }
}