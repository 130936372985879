
.login-layout {
    text-align: left;    
    max-width: fit-content;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 120px auto;

  }
  .logo {
    padding-top: 70px;
    width: 300px;
    max-height: 100px;
    margin-bottom: 22px;
  }

  .logo-top {
    width: 120px;
    padding: 20px
  }
  
  .forgot-password-link {
    color: var(--link-color);
    text-decoration: none;
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 18px;
    text-align: left;
 }
  
  
  
  .page-title {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: var(--tertiary-accent-color);
    margin-bottom: 0px;
    text-align: left;
    max-width: 300px;

  }
  
  .forgot-password-instructions,
  .verification-code-instructions,
  .resend-instructions {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--secondary-text-color);
    text-align: left;
    margin-bottom: 0px;
    max-width: 300px;
  }
  
  .new-password-instructions {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--secondary-text-color);
    text-align: left;
    margin-bottom: 0px;
    align-self: flex-start;
    margin-left: 4px;
  }
  
  .return-signin-link {
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: var(--link-color);
    text-decoration: none;
    margin-top: 20px;
  }
  
  .return-signin-link span {
    color: var(--link-color);
  }
  
  .resend-link {
    color: var(--link-color);
    cursor: pointer;
  }
  
  .return-signin-link:hover,
  .resend-link:hover {
    text-decoration: none;
  }
  
  .instructions {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--secondary-text-color);
    margin-top: 10px;
    text-align: center;
    align-self: center;
  }
  
  .instructions-box {
    margin-bottom: 18px;
    width: 100%;
    margin-left: 0;
  }
  
  :root {
    --primary-accent-color: #EB3D9F;
    --primary-accent-shade-1-color: #D85CA3;
    --secondary-accent-color: #D71683;
    --tertiary-accent-color: #232021;
    --quaternary-accent-color: #EEEEEE;
    --error-color: #F44336;
    --text-color: #000000;
    --success-color: #4CAF50;
    --link-color: #007DFF;
    --secondary-text-color: #675f63;
    --content-page-background-color: #FCEDF3;
  }
  

  .language-switcher {
    margin-left: auto;
    margin-right: 40px;
  }
  
  .language-switcher select {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
  }

  .navbar {
    width: 100%;
    height: 64px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid var(--primary-accent-color);
    margin-bottom: 20px;
  }
  
  .navbar-logo {
    height: 40px; 
  }
  .language-switcher {
    position: relative;
  }
  
  .language-switcher {
    position: relative;
  }
  
  .language-select {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
    cursor: pointer;
    color: #333;
    outline: none;
    transition: border-color 0.2s;
  
    &:hover,
    &:focus {
      border-color: #EB3D9F;
    }
  }
  
  .language-select option {
    background-color: #fff;
    color: #333;
  }


::-webkit-scrollbar
{
  width: 10px;  /* for vertical scrollbars */
  height: 10px; /* for horizontal scrollbars */
}

/* ::-webkit-scrollbar-track
{
  background: var(--quaternary-accent-color);
} */

::-webkit-scrollbar-thumb {
  background: lightgray !important;
  border-radius: 9.999px !important;
  outline: unset !important;
}

.app-text-title-color {
  color: black;
}

.app-text-title-color-white {
  color: white;
}

.text-bold {
  font-weight: bold;
}

.text-medium-bold {
  font-weight: 600 !important;
}

.text-align-justify {
  text-align: justify;
}

.box-shadow {
  box-shadow: 0px 5px 3px 3px rgba(145, 145, 145, 0.2);
}

.app-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 80%;
  background-color: white;
  border-radius: 10px;
}

.app-modal:focus-visible {
  outline: none;
}

@media only screen and (max-height: 950px) {
  .app-modal {
    height: 80%;
  }
}

@media only screen and (max-width: 1000px) {
  .app-modal {
    width: 50%;
  }
}

.app-modal-title {
  display: flex;
  justify-content: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
}

.app-modal-title-text {  
  font-size: 24px;
  font-weight: 600;    
  padding-bottom: 10px;
  border-bottom: 2px solid gray;
}

  