html, body {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-link {
  text-decoration: none;
  font-family: Inter;
  color: darkslategray
}

.complain-conversation-item {
  border: 0px solid #E8EAEE;
  padding: 10px;   
  border-radius: 10px;
  margin-bottom: 10px;
}


.complain-conversation-item .messsage-info {
  font-size: smaller;
  margin-top: 10px
}

.no-message {
  color: red;
  font-style: italic;
}

.complain-conversation-item-left {
  background-color: #FEF5F9;
  /* margin-right: 40%; */
}

.complain-conversation-item-right {
  background-color: rgb(210, 238, 255);
  /* text-align: right; */
  /* float:right; */
  /* margin-left: 40%; */
  /* display: inline-block; */
}